import React, { ChangeEvent } from "react";
import { Modal, Container, Button, Form, Spinner, Nav } from "react-bootstrap";
import { SingleDropdownListWithoutSearch } from "../../Common/Components/SingleDropdownListWithoutSearch";
import ReportViewer from "./ReportViewer";
import DatePicker from "react-datepicker";
import { CustomerService } from "../../Common/Services/CustomerService";
import moment from "moment-timezone";
import { Utils } from "../../Common/Utilis";
import { resolve, reject } from "q";
import { Typeahead } from "react-bootstrap-typeahead";
let storage = JSON.parse(localStorage.getItem("storage")!);
let currentUtcTime = storage === null ? "America/Chicago" : (storage.currentUtcTime as any);
let tenantID = storage === null ? 0 : storage.tenantID as any;
  
export class DetailedReport extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      urlParameter:"",
      reportId:this.props.reportId,
      reportName:this.props.reportName,
      iframeUrl:"",
      formData: {
        selectedcolumn: "All",
        filterValue: "",
        startDate: null,
        endDate: null,
      },
      selectedExcludes: [],
      orders: [
        {
          id: 1,
          orderNo: "CO#123",
          customerCode: "C1001",
          customerName: "ABC Enterprises",
          customerPONo: "1234",
          orderDate: "1/10/24",
          orderTotal: "$1000",
          jobNo: "JO#123",
          partNo: "PN123",
          partDescription: "SAMPLE1",
          drawingNo: "DN456",
          drawingRevision: "Rev A",
        },
        {
          id: 2,
          orderNo: "CO#124",
          customerCode: "C1002",
          customerName: "XYZ Corp",
          customerPONo: "5678",
          orderDate: "1/11/24",
          orderTotal: "$1500",
          jobNo: "JO#124",
          partNo: "PN124",
          partDescription: "SAMPLE2",
          drawingNo: "DN457",
          drawingRevision: "Rev B",
        },
        {
          id: 3,
          orderNo: "CO#125",
          customerCode: "C1003",
          customerName: "LMN Ltd",
          customerPONo: "9101",
          orderDate: "1/12/24",
          orderTotal: "$2000",
          jobNo: "JO#125",
          partNo: "PN125",
          partDescription: "SAMPLE3",
          drawingNo: "DN458",
          drawingRevision: "Rev C",
        },
      ],
      selectOptions: [
        {
          id: "All",
          name: "All",
        },
        { id: "Customer Name", name: "Customer Name" },
        { id: "Customer Code", name: "Customer Code" },
       
      ],
      options: [
        "Part No.",
        "Part description",
        "Qty",
        "Unit price",
        "Total price",
        "Job No.",
        "Customer code",
      ],
      filterOptions: [],
    };
  }

  handleSelectedItem = (control: any, id: any) => {
    if (this != undefined) {
      if (control === "Customer" && id.length > 0) {
        console.log("idd", id);
        let formData = this.state.formData;
        formData.selectedcolumn = id;
        this.setState({ formData });

        if (id === "Customer Name") this.GetCustomer();
        else this.setState({ filterOptions: [] });
      }
    }
  };

  handleInputChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  GetCustomer = () => {
    let requestlist: any = {};
    CustomerService.GetCustomerlist(requestlist)
      .then(async (result: any | null) => {
        if (result !== null) {
          console.log("ress", result);
          let selectOptions = result
            .filter((item) => item.status === "Active")
            .map((item) => ({
              id: item.customer_id,
              name: item.company_name,
            }));
          this.setState({ filterOptions: selectOptions });
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  };

  handleChange = (range) => {
    this.setState({
      formData: {
        ...this.state.formData,
        startDate: range[0],
        endDate: range[1],
      },
    });
  };

  handleExcludeChange = (option) => {
    const { selectedExcludes } = this.state;
    this.setState({
      selectedExcludes: selectedExcludes.includes(option)
        ? selectedExcludes.filter((item) => item !== option)
        : [...selectedExcludes, option],
    });
  };

  runReport = () => {
debugger;

const cdate=  new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);





  const datefrom=(this.state.formData.startDate === null || this.state.formData.startDate === undefined)
  ? firstDayOfMonth
  : this.state.formData.startDate === ""
    ? firstDayOfMonth
    : new Date(this.state.formData.startDate);



    const dateto=(this.state.formData.endDate === null || this.state.formData.endDate === undefined)
    ? new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
    : this.state.formData.endDate === ""
      ? new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
      : new Date(this.state.formData.endDate);

    //const url  = `http://localhost:56170/Default.aspx?reportid=${this.props.reportId}&tenantid=${tenantID}&Datefrom=${moment(datefrom).format("MM/DD/YY")}&Dateto=${moment(dateto).format("MM/DD/YY")}&SearchType=${this.state.formData.selectedcolumn}&SearchValue=${this.state.formData.filterValue}`;

   const url  = `https://demogcwui.azurewebsites.net/Default.aspx?reportid=${this.props.reportId}&tenantid=${tenantID}&Datefrom=${moment(datefrom).format("MM/DD/YY")}&Dateto=${moment(dateto).format("MM/DD/YY")}&SearchType=${this.state.formData.selectedcolumn}&SearchValue=${this.state.formData.filterValue}`;
  

    const FormData = {
      ...this.state.formData,
      excludes: this.state.selectedExcludes,
    
      
    };
    this.setState({ iframeUrl: url });
   
//const url :any =  Showing {from} to {to} of {size} entries;

    console.log("form data", FormData);
  };

  handleChangeBuyer = (event: any) => {
    console.log("event", event);
    let formData = { ...this.state.formData };
    if (event.length > 0) {
      formData.filterValue = event[0].name;
    }
    this.setState({ formData: formData });
  };

  render() {
    debugger;
    console.log(this.props);
    const { iframeUrl } = this.state;

  const cdate=  new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime));
    const firstDayOfMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);
    return (
      
      <Modal
        id="detailedReportModal"
        show={this.props.show}
        onHide={this.props.onHide}
        aria-labelledby="fullscreen-modal"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.ReportName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <div
              className="row g-3"
              style={{ height: "85vh", marginLeft: "-2%" }}
            >
              <div
                className="col-md-3"
                style={{
                  height: "100%",
                  overflowY: "auto",
                  margin: "0px",
                  padding: "10px",
                  position: "relative",
                }}
              >
                <div
                  className="p-2 mt-3 mb-3"
                  style={{
                    backgroundColor: "var(--bs-light)",
                    color: "grey",
                    fontWeight: "500",
                  }}
                >
                  Filter Options
                </div>
                <Form.Group controlId="customercode">
                  <Form.Label className="font-semibold">Customer</Form.Label>
                  <div className="" style={{ width: "50%" }}>
                    <SingleDropdownListWithoutSearch
                      itemList={this.state.selectOptions}
                      handleSelectedItem={this.handleSelectedItem.bind(
                        this,
                        "Customer"
                      )}
                      defaultItem={this.state.formData.selectedcolumn}
                      defaultText={"Select Filter"}
                      defaultName={this.state.formData.selectedcolumn}
                      controlID="1"
                      id={"ddlselected"}
                    />
                  </div>
                </Form.Group>

                <Form.Group controlId="emptyTextField" style={{ width: "56%" }}>
                  {this.state.formData.selectedcolumn === "Customer Name" ? (
                    <Typeahead
                      id="selections-example"
                      labelKey="name"
                      onInputChange={(
                        text: string,
                        e: ChangeEvent<HTMLInputElement>
                      ) => {
                        this.state.formData.filterValue = text;
                      }}
                      onChange={(e) => this.handleChangeBuyer(e)}
                      className="mb-3 mt-4 w-full"
                      options={this.state.filterOptions}
                      placeholder="Enter Value"
                      selected={this.state.Defaultselected}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Enter value"
                      className="mb-3 mt-4"
                      value={this.state.formData.filterValue}
                      onChange={this.handleInputChange("filterValue")}
                    />
                  )}
                </Form.Group>

                <Form.Group controlId="daterange" style={{ width: "100%" }}>
                  <Form.Label className="font-semibold">Date Range</Form.Label>
                  <div className="date-picker calendar-picker d-flex align-items-center">
                    <DatePicker
                      id="startDate"
                    //  selected={this.state.formData.startDate}

                    selected={
                      (this.state.formData.startDate === null || this.state.formData.startDate === undefined)
                        ? firstDayOfMonth
                        : this.state.formData.startDate === ""
                          ? firstDayOfMonth
                          : new Date(this.state.formData.startDate)
                    }

                      onChange={(date) =>
                        this.handleInputChange("startDate")(date)
                      }
                      tabIndex={this.state.tabEnable}
                      className="form-control px-0 text-center"
                      placeholderText={"MM/DD/YY"}
                      style={{ width: "30%" }}
                      dateFormat="MM/dd/yy"
                      autoComplete="off"
                    />
                    <span className="mx-2">-</span>
                    <DatePicker
                      id="endDate"
                    //  selected={this.state.formData.endDate}

                    selected={
                      (this.state.formData.endDate === null || this.state.formData.endDate === undefined)
                        ? new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                        : this.state.formData.endDate === ""
                          ? new Date( Utils.convertUtcToTimezoneFormat(new Date(),currentUtcTime))
                          : new Date(this.state.formData.endDate)
                    }

                      onChange={(date) =>
                        this.handleInputChange("endDate")(date)
                      }
                      tabIndex={this.state.tabEnable}
                      className="form-control px-0 text-center"
                      placeholderText={"MM/DD/YY"}
                      style={{ width: "30%" }}
                      dateFormat="MM/dd/yy"
                      autoComplete="off"
                    />
                  </div>
                </Form.Group>

                {/* <Form.Group controlId="exclude">
                  <Form.Label className="font-semibold mt-3">
                    Exclude
                  </Form.Label>
                  <div
                    className="single-select"
                    style={{
                      border: "1px solid #ced4da",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.options.map((option, index) => (
                      <Form.Check
                        key={index}
                        type="checkbox"
                        label={option}
                        checked={this.state.selectedExcludes.includes(option)}
                        onChange={() => this.handleExcludeChange(option)}
                      />
                    ))}
                  </div>
                </Form.Group> */}

                <div className={"footer-section3"}>
                  <div>
                    <div className="d-flex gap-3 align-items-center justify-content-end height-72">
                      <div className="d-flex gap-3 align-items-center">
                        <Button
                          variant="primary"
                          type="button"
                          className="space btn btn-primary btn-discard"
                          // onClick={() =>
                          //   this.handleDiscard(this.state.orderUniqueNo)
                          // }
                        >
                          Save
                        </Button>
                        <Button
                          id="btnSave"
                          variant="primary"
                          type="button"
                          onClick={this.runReport}
                        >
                          Run
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-9"
                style={{ backgroundColor: "#ffffff", height: "100%" }}
              >
                <div
               //   className="border border-grey p-5"
                 // style={{ height: "100%", overflowY: "auto" }}
                >
              
              {iframeUrl && (
                  <ReportViewer reportUrl={iframeUrl} />
              )}
                </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}
